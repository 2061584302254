import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const AboutPage = ({data}) => {

  const cities = data.allCities.group;

  let nestedCities = {};
  cities.map((cityPath) => {
    let country, state, city;
    [country, state, city] = cityPath.fieldValue.split("/");

    nestedCities[country] = nestedCities[country] || {name: cityPath.edges[0].node.country, places: {}};
    nestedCities[country].places[state] = nestedCities[country].places[state] || {name: cityPath.edges[0].node.state, places: {}};
    nestedCities[country].places[state].places[city] = nestedCities[country].places[state].places[city] || {name: cityPath.edges[0].node.city};
    nestedCities[country].places[state].places[city].total = cityPath.totalCount;
    nestedCities[country].places[state].places[city].slug = cityPath.fieldValue;
  })

  const c = Object.keys(nestedCities).map((country) => {
    return (
      <div>
        <h2>{nestedCities[country].name}</h2>
          {Object.keys(nestedCities[country].places).map((state) => {
            return (
            <div>
              <h3>
                {nestedCities[country].places[state].name}
              </h3>
              {Object.keys(nestedCities[country].places[state].places).map((city) => {
                return (
                <div className='city-entry'>
                  <Link to={nestedCities[country].places[state].places[city].slug}>
                    {nestedCities[country].places[state].places[city].name}
                    <span className="count">
                      {nestedCities[country].places[state].places[city].total}
                    </span>
                  </Link>
                </div>
                )
              })
            }
            </div>
            )
          })
        }
      </div>
    )
  })

  console.log(nestedCities);

  // const contributorLinks = contributors.map((item) => {
  //   return (
  //     <Link to={`contributors/${item.fieldValue}`}>{item.nodes[0].images[0].credit}</Link>
  //   )
  // })

  return (
  <Layout>
    <SEO title="All sign locations" image={data.placeholderImage.childImageSharp.fluid.src}/>
    <h2>Sign photos by City</h2>
    <p>This archive features COVID-19 signs from {cities.length} cities in {Object.keys(nestedCities).length} countries. If you don't see your city listed yet, we'd love to <Link className='more-links' to="/submit">include your images.</Link></p>

    <div className='city-list'>
      {c}
    </div>
  
  </Layout>
)}

export const query = graphql`
query AllCities {
  placeholderImage: file(relativePath: { eq: "banner-narrow.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allCities: allLocationsJson(sort: {fields: images___date, order: DESC}) {
    group(field: fields___cityFullPath) {
      fieldValue
      totalCount
      edges {
        node {
          country
          state
          city
        }
      }
    }
  }
}
`

export default AboutPage
